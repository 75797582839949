import React, { Component } from 'react';

import request from 'superagent';

import CountrySelector from './CountrySelector';

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      email: null,
      country2L: null,
      country3L: null,
      error: null,
      loading: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const state = {};
    state[event.target.id] = event.target.value;
    this.setState(state);
  }

  handleSelect(countryObj) {
    const state = {
      country2L: countryObj.alpha2,
      country3L: countryObj.alpha3,
    };

    this.setState(state);
  }

  async handleSubmit(event) {
    const {
      name,
      email,
      country2L,
      country3L,
    } = this.state;
    const { history } = this.props;

    event.preventDefault();

    if (!country2L) {
      this.setState({ error: { msg: 'Please choose a country' } });
      return;
    }

    if (!name) {
      this.setState({ error: { msg: 'Please enter a name' } });
      return;
    }

    if (!email) {
      this.setState({ error: { msg: 'Please enter an email' } });
      return;
    }

    this.setState({ loading: true });

    const { error, urlId } = await request
      .post('/api/registrations')
      .send({
        name,
        email,
        country2L,
        country3L,
      })
      .then(res => res.body);

    if (error) {
      this.setState({ error, loading: false });

      return;
    }

    history.push(`/naut/${urlId}`);
  }

  render() {
    const { error, loading } = this.state;

    return (
      <div>
        <h1>Register</h1>

        { error && <div className="alert alert-danger" role="alert"> { error.msg } </div> }

        <form onSubmit={this.handleSubmit}>

          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input id="name" className="form-control" type="text" placeholder="Enter name" onChange={this.handleChange} required />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input id="email" className="form-control" type="email" aria-describedby="emailHelp" placeholder="Enter email" onChange={this.handleChange} required />
            <small id="emailHelp" className="form-text text-muted">We will never share your email with anyone else.</small>
          </div>

          <div className="form-group" style={{ width: '400px' }}>
            <label htmlFor="country">Country</label>
            <CountrySelector
              id="country"
              handleCountryChange={coutryObject => this.handleSelect(coutryObject)}
            />
          </div>

          <button type="submit" className="btn btn-primary" disabled={loading}>Register</button>

        </form>
      </div>
    );
  }
}

export default Form;
