import React, { Component } from 'react';

import Select from 'react-select';
import countries from '../countries.json';

class CountrySelector extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selectedOption) {
    const { handleCountryChange } = this.props;

    handleCountryChange(selectedOption);
  }

  render() {
    const { id } = this.props;

    return (
      <Select
        id={id}
        onChange={this.handleChange}
        options={countries}
        getOptionValue={option => option.alpha2}
        getOptionLabel={option => option.name}
      />

    );
  }
}

export default CountrySelector;
