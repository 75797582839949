import React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';

import Form from './components/Form';
import Naut from './components/Naut';

import './App.css';

const App = () => (
  <Router>
    <div className="container">
      <Route exact path="/" component={Form} />
      <Route path="/naut/:urlId" component={Naut} />
    </div>
  </Router>
);

export default App;
