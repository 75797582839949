import React, { Component } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  RedditIcon,
} from 'react-share';

import request from 'superagent';

class Naut extends Component {
  constructor(props) {
    super(props);

    this.state = {
      naut: null,
      error: null,
    };
  }

  componentDidMount() {
    const { match: { params: { urlId } } } = this.props;

    request
      .get('/api/registrations')
      .query({ urlId })
      .then(res => res.body)
      .then(({ error, naut }) => this.setState({ naut, error }));
  }

  render() {
    const { error, naut } = this.state;
    const { match: { params: { urlId } } } = this.props;

    const title = "Your journey is mankind's future!";
    const hashtags = ['spacecoffins', 'space'];
    const url = `https://register.spacecoffins.com/naut/${urlId}`;

    return (
      <div>
        { error && <div className="alert alert-danger" role="alert"> { error.msg } </div> }

        { naut
          ? (
            <div>
              <h1>Naut: {naut.fullName}</h1>

              <h2>Id: SPC{`${naut.id}`.padStart(3, '0')}</h2>

              <div className="row">
                <div className="column">
                  <FacebookShareButton url={url} qoute={title} hashtag={`#${hashtags[0]}`}>
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                </div>
                <div className="column">
                  <TwitterShareButton url={url} title={`${title} @spacecoffins`} hashtags={hashtags}>
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                </div>
                <div className="column">
                  <RedditShareButton url={url} title={title}>
                    <RedditIcon size={32} round />
                  </RedditShareButton>
                </div>
                <div className="column">
                  <WhatsappShareButton url={url} title={title}>
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <img className="img-fluid" src={`/images/${urlId}.jpg`} alt="Space Coffin" />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h1>Loading...</h1>
            </div>
          )
        }
      </div>
    );
  }
}

export default Naut;
